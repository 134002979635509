@import "../themes/themes";

@include themifyRoot() {
  .main-app {
    a {
      color: themed(anchorColor);
      text-decoration: none;
    }

    table, td, th {
      border-color: themed(tableBorderColor);
      color: themed(defaultFgColor);
    }

    code {
      background-color: themed(codeBgColor);
    }

    *.text-muted {
      color: themed(altMutedColor) !important;
    }

    .form-control {
      color: themed(formControlFgColor);
      background-color: themed(formControlBgColor);
    }

    .form-control::placeholder {
      color: themed(formControlPlaceholderColor);
    }

    .toast:not(.show) {
      display: flex;
    }
  }
}
