@import "../themes/themes";

@include themifyRoot() {
  .modal-content {
    background-color: themed(dialogBgColor);
    color: themed(dialogFgColor);

    .modal-header {
      padding: 20px;
      border-bottom: 1px solid themed(dialogBorderColor);
    }

    .dialog-footer {
      border-top: 1px solid themed(dialogFooterBorderColor);
      background-color: themed(dialogFooterBackgroundColor);
    }
  }
}
