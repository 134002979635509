// The CSS for the Element breadcrumb is specified here to ensure that it's style can be overridden.
// In it's current position (as a component), the component-level stylesheet cannot access the
// elements, so we specify it in a more generic location.
@import "themes/themes";

.main-app {
  @include themify() {
    .xng-breadcrumb-link {
      text-decoration: none;
    }

    .xng-breadcrumb-root {
      color: inherit;
    }

    .xng-breadcrumb-seperator,
    .xng-breadcrumb-trail {
      color: themed(activeBreadcrumbColor);
    }

    .header .quickAction .closeButton .scalarClose {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .header .quickAction .adminButton {
      position: absolute;
      top: 0px;
      right: 26px;
    }

    .header .quickAction .headerButton {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
