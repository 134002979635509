// styles in src/style directory are applied to the whole page
@import "../assets/fonts/inter.scss";
@import "~bootstrap/scss/bootstrap";
@import "~ngx-ui-switch/ui-switch.component.scss";
@import "themes/themes";
@import "components/icons";
@import "components/ibox";
@import "components/dialog";
@import "components/bootstrap_override";
@import "components/element";
@import "~angular2-toaster/toaster";
@import "riot";

body {
  margin: 0;
  padding: 0;
}

@include themifyRoot() {
  color: themed(defaultFgColor);

  // HACK: We specify the spinner color here because we can't use the mixin
  // in the spinner's css due to angular scoping
  .sk-cube:before {
    background-color: themed(spinnerColor) !important;
  }
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-backdrop.in {
  opacity: 0.5;
}

button {
  cursor: pointer;
}

.label-block {
  display: block;
}

.text-muted {
  display: block;
  font-size: 12px;
}

.error-text {
  color: #bd362f !important;
}

h3 {
  font-size: 1.13em;
  font-weight: 600;
}

.sticker-picker {
  .mat-tab-label {
    padding: 0 !important;
    min-width: 50px !important;
  }
}
.sticker-pack {
  .mat-tab-labels,
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }
}
