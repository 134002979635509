@import "../themes/themes";

.main-app {
  @include themify() {
    .ibox {
      background-color: themed('containerBgColor');
      padding: 0;
      margin-bottom: 30px;
      box-shadow: 0 2px 4px themed('containerShadowColor');
    }

    .ibox .ibox-title {
      padding: 5px;
      border-bottom: 1px solid themed('containerBorderColor');
      border-top: 2px solid themed('containerBorderColor');
    }

    .ibox .ibox-title h5 {
      display: inline;
      font-size: 1.1em;
      padding: 5px;
      margin: 0;
    }

    .ibox .ibox-content {
      padding: 15px;
    }

    @media only screen and (max-width: 480px) {
      .ibox .ibox-content {
        padding: 0;

        .lottie-box {
          width: 60px !important;
          height: 60px !important;
        }
      }
    }

    .ibox .ibox-title .ibox-icon {
      float: right;
      margin-top: 4px;
      margin-right: 5px;
      cursor: pointer;
    }

    .ibox .ibox-title.ibox-title-collapsible {
      cursor: pointer;
    }
  }
}